<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">{{ title }}</div>
      <el-form
        ref="ruleForm"
        v-loading="loading"
        :model="ruleForm"
        :rules="rules"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username">
          <el-input
            v-model="ruleForm.username"
            placeholder="请输入您的账号/手机号码"
            clearable
          >
            <el-button slot="prepend" icon="el-icon-lx-people"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            type="password"
            placeholder="请您的输入密码"
            clearable=""
            @keyup.enter.native="submitForm('ruleForm')"
          >
            <el-button slot="prepend" icon="el-icon-lx-lock"></el-button>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm('ruleForm')">
            登 录
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/admin/user';

export default {
  data: function() {
    return {
      title: process.env.VUE_APP_TITLE,
      ruleForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入账号/手机号码', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      loading: false
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          login(this.ruleForm)
            .then(resp => {
              if (resp.code === 500) {
                this.$message({
                  message: resp.msg,
                  type: 'warning'
                });
              } else {
                sessionStorage.setItem('token', resp.other.token);
                sessionStorage.setItem('userId', resp.data.userid);
                sessionStorage.setItem('username', resp.data.name);
                sessionStorage.setItem('roleName', resp.data.roleName);
                sessionStorage.setItem('roleCode', resp.data.roleCode);
                sessionStorage.setItem('department', resp.data.department);
                sessionStorage.setItem('departmentId', resp.data.departmentId);
                sessionStorage.setItem('canteenType', resp.data.canteen); // 用户所在食堂
                this.$router.push('/');
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid #ddd;
  text-align: center;
  font-size: 2rem;
  color: #fff;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 450px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);

  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>
